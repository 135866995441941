import React from "react"
import { Unity, useUnityContext } from "react-unity-webgl"

function App() {
  const { isLoaded, loadingProgression, unityProvider } = useUnityContext({
    loaderUrl: "unity-build/Build/unity-build.loader.js",
    dataUrl: "unity-build/Build/unity-build.data",
    frameworkUrl: "unity-build/Build/unity-build.framework.js",
    codeUrl: "unity-build/Build/unity-build.wasm",
    streamingAssetsUrl: "unity-build/StreamingAssets"
  })

  const loadingPercentage = Math.round(loadingProgression * 100)

  return (
    <div>
      {!isLoaded && <p>Loading… {loadingPercentage}%</p>}
      <Unity unityProvider={unityProvider} style={{width: '100vw', height: '100vh'}} />
    </div>
  )
}

export default App
